@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: OpenSauceOnce;
    src: url('../fonts/OpenSauce/OpenSauceOne-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}

@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-Medium.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-SemiBold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}


@font-face{
    font-family: Poppins;
    src: url('../fonts/Poppins/Poppins-ExtraBold.ttf');
    font-weight: 900;
    font-display: swap;
}
















@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Regular.ttf');
    font-display: swap;  
}

@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Bold.ttf');
    font-weight: bold;
    font-display: swap;  
}

@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Bold.ttf');
    font-weight: 500;
    font-display: swap;  
}

@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Bold.ttf');
    font-weight: 600;
    font-display: swap;
}

@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Light.ttf');
    font-weight: 300;
    font-display: swap;
}

@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Bold.ttf');
    font-weight: 700;
    font-display: swap;
}

@font-face{
    font-family: Lato;
    src: url('../fonts/Lato/Lato-Black.ttf');
    font-weight: 800;
    font-display: swap;
}


:root{
    --primary-ss-color:#5a189a;
    --primary-ss-color2:#f7f7ff;
    --secondary-ss-color: #af0e0e;
    --primary-ssl-color: #bde3fb;
    --secondary-ssl-color: #d20909;
    --white-color: #ffffff;
    --black-color: #000000;
    --primary-ss-font-family: OpenSauceOnce, sans-serif;
    --secondary-ss-font-family: Poppins, sans-serif;
}

*{
    padding: 0;
    margin: 0;
}

ol, ul{
    margin-top: 0px;
    margin-bottom: 0px;
}

body{
    font-family: OpenSauceOnce, sans-serif !important;
}

.text-poppins{
    font-family: Poppins, sans-serif;
}

.text-lato{
    font-family: Lato, sans-serif !important;
}

.text-opensans{
    font-family: OpenSauceOnce, sans-serif;
}

/* .header-logo{
    color: #00477f;
} */

/* .header-logo:hover{
    color: var(--primary-zk-color)
} */

.PhoneInputInput {
    border: 0!important;
    background-color: white !important;
}

.PhoneInputInput:focus-visible {
    border: 0!important;
    outline: 0;
}

.login-page .PhoneInputInput{
    background-color: var(--secondary-zkl-color) !important;
}

.text-ss-primary{
    color: var(--primary-ss-color) !important;
}

.text-ss-secondary{
    color: var(--secondary-ss-color);
}
.text-ss-black{
    color: var(--black-color);
}

.bg-ss-primary{
    background-color: var(--primary-ss-color) !important;
}

.bg-ss-primary2{
    background-color: var(--primary-ss-color2);
}

.bg-ss-secondary{
    background-color: var(--secondary-ss-color);
}

.bg-ssl-primary{
    background-color: var(--primary-ssl-color);
}
.bg-ssl-secondary{
    background-color: var(--secondary-ssl-color);
}
.bg-secondary-ssl-color{
    background-color: var(--secondary-ssl-color);
}

.secondary-ssl-color{
    color: var(--secondary-ssl-color);
}

.w80px{
    width: 80px;
}

.home-services-img{
    /* width: 100%; */
    width: 100px;
    max-width: 200px;
}
.border-ss-primary{
    border-top: 3px solid var(--primary-ss-color);
    border: none;
    height: 3px;
    opacity: 1;
    color: var(--primary-ss-color);
    background-color: var(--primary-ss-color);

}
.border-ss-white{
    border-top: 2px solid #fff;
    border: none;
    height: 2px;
    opacity: 1;
    color: #fff;
    background-color: #fff;

}

.fs-ss-92{
    font-size: 92px;
}

.fs-ss-80{
    font-size: 65px;
}

.fs-ss-74{
    font-size: 74px;
}

.fs-ss-68{
    font-size: 68px;
}

.fs-ss-58{
    font-size:58px;
}

.fs-ss-56{
    font-size:60px;
}
.fs-ss-52{
    font-size:52px;
}

.fs-ss-48{
    font-size: 48px;
}

.fs-ss-46{
    font-size: 46px;
}

.fs-ss-40{
    font-size: 40px;
}
.fs-ss-35{
    font-size: 35px;
}

.fs-ss-32{
    font-size: 32px;
}

.fs-ss-30{
    font-size: 30px;
}

.fs-ss-28{
    font-size: 28px;
}
.fs-ss-26{
    font-size: 26px;
}
.fs-ss-24{
    font-size: 24px;
}
.fs-ss-22{
    font-size: 22px;
}

.fs-ss-20{
    font-size: 20px;
}

.fs-ss-18{
    font-size: 18px;
}

.fs-ss-16{
    font-size: 16px;
    font-weight: 500 !important;
    line-height: 1.4rem !important;
}
.fs-ss-17{
    font-size: 17px;
}
.fs-ss-15{
    font-size: 15px;
}

.fs-ss-14{
    font-size: 14px !important; 
}

.fs-ss-13{
    font-size: 13px; 
}

.fs-ss-13-card{
    font-size: 13px; 
}

.fs-ss-12{
    font-size: 12px;
}

.fs-ss-10 {
    font-size: 10px;
}

.fw-ss-900{
    font-weight: 900;
}

.fw-ss-800{
    font-weight: 800;
}

.fw-ss-700{
    font-weight: 700;
}

.fw-ss-600{
    font-weight: 600;
}

.fw-ss-500{
    font-weight: 500;
}

.fw-ss-400{
    font-weight: 400 !important; 
}

.fw-ss-300{
    font-weight: 300;
}

.fw-ss-200{
    font-weight: 200;
}

.fw-ss-100{
    font-weight: 100;
}

.logo-img{
    height:60px;
}
 
.container-ss{
    max-width: 90%;
    padding-right: 2%;
    padding-left: 2%;
    margin-right: auto;
    margin-left: auto;
}

.container-ss2{
    max-width: 86%;
    padding-right: 7%;
    padding-left: 7%;
    margin-right: auto;
    margin-left: auto;
}



#website-nav .navbar-toggler:focus{
    outline: none;
    box-shadow: none;
}

#website-nav .navbar-toggler{
    border: none;
    border-radius: 0;
    background-color: #fff;
}

#website-nav .nav-link{
    color: #000;
    margin-right: 16px;
    padding-top: 0;
    padding-bottom: 0;
    font-size: 15px;
}

#website-nav .nav-link:hover{
    color: var(--primary-ss-color);
}


#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}

#website-nav .sub-nav-menu:hover .sub-sub-child-menu .dropdown-menu {
    display: none;
}

#website-nav .sub-nav-menu .dropdown-menu {
    margin-top: 0;
}
#website-nav .sub-child-menu:hover .dropdown-menu {
    display: block;
    visibility: visible;
    margin-top: -10px;
}

#website-nav .sub-child-menu .sub-sub-child-menu:hover .dropdown-menu{
    display: block;
    visibility: visible;
}

#website-nav .active{
    color: var(--secondary-ss-color);
}

#website-nav .dropdown-toggle{
    padding-right: 0;
    padding-left: 0;
    margin-right: 0;
    margin-left: 0;
}

.dropdown-item.active, .dropdown-item:active{
    background-color: var(--primary-ss-color);
}

#website-nav .sub-child-menu .dropdown-menu {
    /* display: none; */
    visibility: hidden;
    margin-top: 0;
}
#website-nav .sub-child-menu .dropdown-menu a{
    padding: 5px 10px;
    font-size: 15px;

}
#website-nav .sub-child-menu .dropdown-menu{
    width: 240px;
}
#website-nav .dropdown-item{
    cursor: pointer;
}
.navbar-offcanvas{
    /* background-color: var(--primary-ss-color);
    color: #fff; */
    padding: 0rem;
}

.take-control-bg{
    background-color: var(--primary-ssl-color) ;
    /* background color to -10vw */
    background-position: 0 -10vw;
}
.minusBg10 {
    margin-top: -100px;
}

.minusBg12 {
    margin-top: -120px;
}

.lh-1-2{
    line-height: 1.2;
}


.lb{
    margin-top: 30px;
    margin-left: 5px;
    padding-left: 30px;
    border-left: 4px solid #004bda;
}



.slick-slide-title {
    color: #fff;
    padding: 10px;
    position: absolute;
    /* text-transform: ; */
    left: 8vw;
    font-size: 3.2vw;
    font-family: Poppins;
    font-weight: 700;
    top: 10%;
    width: 80%;
    line-height: 1.1;
    letter-spacing: 2px;
  }
  .slick-slide-title-desc {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 8vw;
    font-size: 3.5vw;
    font-weight: 500;
    top: 48%;
    width: 80%;
  }
  .slick-slide-btn{
    position: absolute;
    left: 9vw;
    font-weight: 500;
    top: 67%;
    width: 80%;
  }

  .slick-slide-button{
    font-size: 1.4vw;
  }
  


.bg-contact-opacity{
    background-color: #181818;
    opacity: 0.95;
}

.text-white{
    color: #ffffff;
}
.banner-section{
    position: relative;
}
.about-col-bg-white{
    background-color: #ffffff;
    border-radius: 10px;
    align-content: center;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
}
.about-col-b{
    border: 3px solid #004bda;
    border-radius: 10px;
    align-content: center;
}

.w80px {
    width: 80px;
}

.border-header{
    border-bottom: 4px solid var(--secondary-ss-color) !important;
}

.border-ss-primary2 {
    background-color: var(--primary-ss-color);
    border: none;
    color: var(--primary-ss-color);
    height: 2px;
    opacity: 1;
}
.accordion-button{
    padding: 10px 20px !important;
}
.accordion-button:focus{
    box-shadow: 0;
}
.accordion-button:not(.collapsed){
    background-color: #ededed !important;
}
.accordion-button:focus{
    box-shadow: none !important;
}
.accordion-item {
    border-left: 0 !important;
    border-radius: 0 !important;
    border-right: 0 !important;
    border-top: 0 !important;
}

.accordion{
    border: 0;
}

.splash-screen {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #f2f3f8;
    color: #5E6278;
    line-height: 1;
    font-size: 14px;
    font-weight: 400;
}
  
.splash-screen span {
color: #5E6278;
transition: none !important;
text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
}

.splash-screen img {
margin-left: calc(100vw - 100%);
margin-bottom: 30px;
height: 38px !important;
}

.slick-slide-image {
    max-width: 100%;
    height: auto;
    box-shadow: 0 13px 27px -5px hsla(240, 30.1%, 28%, 0.25), 0 8px 16px -8px hsla(0, 0%, 0%, 0.3),
      0 -6px 16px -6px hsla(0, 0%, 0%, 0.03);
  }
  .slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0px;
    font-size: 1.5em;
    bottom: 0px;
    width: 100%;
  }

  .slick-slide-content{
    color: #fff;
    position: absolute;
    left: 7vw;
    top: 30%;
    width: 45%;

}


.slider-wrapper {
    width: 100%;

    margin: auto;
  }

  .btn-ss-white{
    background-color: #fff;
    color: black;
    border: 0;
    border-radius: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
  }

.btn-ss-white:hover{
    background-color: rgb(238, 238, 238);
    color: black;
    border: 0;
    padding: 10px 20px;
    /* font-size: 1.1vw; */
    font-weight: 500;
}
.btn-ss-white:focus{
    background-color: rgb(238, 238, 238) !important;
    color: black !important;
}
.home-banner .row{
    margin: 0;
}

.spacer-ss-15{
    height: 15px;
}

.spacer-ss-25{
    height: 25px;
}

.spacer-ss-37{
    height: 37px;
}

.footer-links-section{
    padding-top: 200px;
    padding-bottom: 40px;
}
.border-ss-secondary-footer{
    background-color: var(--secondary-ss-color);
    border: none;
    color: var(--secondary-ss-color);
    height: 3px;
    opacity: 1;
    width: 100px;
}
.border-gray2{
    border-top: 1px solid #ffffff40
}

  .rounded30{
    border-radius: 30px;
  }

  .rounded20{
    border-radius: 20px;
  }

  .rounded10{
    border-radius: 10px;
  }

  .rounded6{
    border-radius: 6px;
  }

  .btn-ss-outline-white{
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    /* height: 3em; */
    min-width: 120px;
    font-family: work-sans;
    font-weight: 600 !important;
    border: 1px solid #fff;

}
.btn-ss-primary{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary{
    background-color: var(--secondary-ss-color);
    color: #fff;
    cursor: pointer;
}


.btn-ss-primary-banner{
    background-color: var(--primary-ss-color);
    color: #fff;
    cursor: pointer;
    /* padding: 2vw 5vw; */
    text-decoration: none;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
}

.btn-ss-primary-banner:hover{
    background-color: var(--primary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
    padding: 1vw 2vw;
}

.btn-ss-white-banner{
    background-color: #fff;
    color: var(--primary-ss-color);
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Work Sans', sans-serif;
    text-transform: capitalize !important;
    letter-spacing: 0.5px;
    border-radius: 30px;
}

.btn-ss-white-banner:hover{
    background-color: #fdfdfd;
    opacity: 0.94;
    color: var(--primary-ss-color);
    cursor: pointer;
    padding: 1vw 2vw;
}

.home-banner-content{
    position: absolute;
    top: 15%;
    width: 55%;
    left: 13%;
}
.home-banner-heading{
    letter-spacing: 3px;
}
.home-banner-btn{
    font-size: 18px;
    border-radius: 30px !important;
}
.btn-ss-primary:hover, .btn-ss-primary:focus{
    background-color: var(--primary-ss-color) !important;
    opacity: 0.94;
    color: #fff !important;
    cursor: pointer;
}

.btn-ss-secondary:hover{
    background-color: var(--secondary-ss-color);
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.btn-ss-secondary:focus{
    background-color: var(--secondary-ss-color) !important;
    opacity: 0.94;
    color: #fff;
    cursor: pointer;
}

.shadow-ss-1{
    box-shadow: 2px 3px 3px 2px #dadada !important;
}
.shadow-ss-2{
    box-shadow: 2px 5px 5px 4px #dadada !important
}

.shadow-ss-left{
    box-shadow: -3px 4px 7px 2px #bbbbbb !important;
}

.border-zk-card-bottom{
    border-bottom: 5px solid var(--primary-ss-color);
    border-left: 0;
    border-right: 0;
    border-top: 0;
}

.padding10xem{
    padding: 10em 0;
}

.padding7xem{
    padding: 7em 0;
}

.padding5xem{
    padding: 5em 0;
}

.padding2-2xem{
    padding: 2.2em 0;
}
.padding3xem{
    padding: 3em 0;
}


.navbar-nav .dropdown-menu {
    background: #fdfdfd;
    border: rgb(255, 255, 255);
    box-shadow: #dadada 0px 0px 2px 0px;
    border-radius: 0;
    border-top: 2px solid var(--primary-ss-color);
    position: static;
    width: 100%;
}

#website-nav .sub-nav-menu:hover .dropdown-menu {
    display: block;
}
#website-nav .sub-nav-menu .dropdown-menu {
    width: max-content;
    margin-top: 0;
}

.emr_featured_col{
    /* middle */
    min-height: 55px;

}

.card-stretch{
    height: calc(100% - 1px);
}



.home-mobile-pic{
    width: 30%;
}
.page-banner-title{
    color: #fff;
    /* padding: 10px; */
    position: absolute;
    font-family: Poppins;
    font-weight: bolder;
    font-size: 3vw;
    top: 40%;
    letter-spacing: 1px;
    color: #000;
    /* width: 80%; */
  }
  .page-banner-text1{
    font-size: 2vw;
  }
  .page-banner-desc{
    font-size: 1.4vw;
    font-weight: 400;
    margin-top: 10px;
    /* margin-top: 1px; */




  }

.navbar-nav .dropdown-menu {
    box-shadow: 0;
    border-radius: 0;
    border: 0;
    padding: 10px;
}

.digital-marketing-icon{
    width: 50% !important;
}
.about-weuse-section{
    min-height: 175px !important;
}

.about-ourphilosophy{
    width: 130px;
    height: 120px;
}
.home-healthimages{
    width: 40%;
}
.home-practice-img{
    width: 100%;
}

.home-practice-animation{
    width: 50%;
}
.whoweserve-healthimages{
    width: 25%;
} 

.about-connection-sec{
    min-height: 460px;
}
.marginTopvitals1{
    margin-top: -180px;
}

.paddingTopvitals2{
    padding-top: 190px;
}

.p-top{
    padding-top: 100px;
}

.p-bottom{
    padding-bottom: 100px;
}

.section1{
    background-position: center left;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}

.section2{
    background-position: center right;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 500px;
}



.table-bordered{
    border: 1px solid #d3d3d3 !important;
}

.border-bottom{
    border-bottom: 1px solid #f51a44 !important;  
}

.shadow-os{
   box-shadow: -3px 3px 4px 1px rgb(0 0 0 / 30%);
}

.icon-size{
    width: 51px;
}


.fw-900{
    font-weight: 900;
}

.fw-800{
    font-weight: 800;
}

.fw-700{
    font-weight: 700;
}


.fw-600{
    font-weight: 600;
}

.fw-500{
    font-weight: 500;
}

.fw-400{
    font-weight: 400 !important;
}

.lh-44{
    line-height: 44px;
}

.section1 .row{
    margin-right: 0;
    margin-left: 0;
}

.btn-ss-white-secondary{
    background-color: #fff;
    border: 3px solid var(--primary-ss-color);
}
.btn-ss-white-secondary:hover{
    background-color: var(--secondary-ss-color);
    color: #fff;
}

.ls-lg-ss-aim{
    letter-spacing: 16px;
}

.provider-img{
    width: 70%;
}

.provider-bg{
    background: linear-gradient(var(--primary-ss-color) 0 50%, white 50% 100%);
    border-radius: 6%;
    padding: 40px 10px 20px 10px;
    box-shadow: 2px 3px 3px 2px #dadada !important;
    margin: 0;
}
.provider-pic-w{
    max-width: 65%;
}
.provider-sec-bg{
    background-color: #F2F5F7;
}

.provider-col-bg{
    background: white;
    border-radius: 0%;
    padding: 20px 10px 5px 10px;
    box-shadow: 1px 1px 2px 2px #dadada;
    margin: 0;
}
.pa{
    position: absolute;
    top: -450px;
}

.home-insurance-img{
    width: 75%;
}

.footer-bg{
    background-color: #d3d3d3;
}

.border-zk-secondary-footer{
    width: 100px;
    height: 3px;
    background-color: var(--secondary-ss-color);
    margin-top: 10px;
    opacity: 1;
    border: var(--secondary-ss-color);
}

.supernova{
    background-color: #ebebff00 !important;
}
.no-row-space .row{
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
}

.bg-none{
    background-color: rgb(0,0,0,0.1);
}

.medicatl-texting ::marker{
    color: var(--secondary-ss-color);
    font-size: 25px;
}


/* Style for the support icon */
#support-agent .support-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: var(--primary-ss-color);
    color: white;
    font-size: 24px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 10000;
}

/* Style for the support popup */
#support-agent .support-popup {
    position: fixed;
    bottom: 80px;
    right: 20px;
    width: 400px;
    height: 500px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 10000;
}

/* Close button for the popup */
#support-agent .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #fff;
}



@media screen and (max-width: 1281px) {
    .home-marketing-section-content .fs-ss-16{
        font-size: 15px;
    }

    #website-nav .nav-link{
        margin-right: 10px;
    }

    #website-nav .fs-ss-16{
        font-size: 15px;
    }

    
}
@media screen and (max-width: 1150px){

}

@media screen and (max-width: 1050px){
    #website-responsive .fs-ss-92{
        font-size: 55px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    .ls-lg-ss-aim{
        letter-spacing: 10px;
    }

}

@media screen and (min-width: 0px) and (max-width: 991px) {
    .fs-ss-testimonials{
        font-size: 15px !important;
    }
    .vh-50{
        height: 55vh !important;
    }
    .w-content{
        width: 100% !important;
    }
}


@media screen and (min-width: 992px) {
    .w-content{
        width: max-content !important;
    }
}

@media screen and (max-width: 991px) {
    .bg-home-banner3{
        background-image: url(../images/Home/New/Banner3.webp);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative !important;
        height: 40vh !important;
    }

    .bg-home-banner4{
        background-image: url(../images/Banners/VirtualScribe.webp);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative !important;
        height: 40vh !important;
    }
    
    .bg-qr-position-img{
        left: -30px !important;
    }
    .w-3{
        width: 27% !important;
    }
    
    .hover-black{
        color: rgb(0, 0, 0) !important;
    }
    .btn-bg-button{
        background-color: var(--primary-ss-color) !important;
        color: white !important;
    }
    .container-ss{
        max-width: 96%;
        padding-right: 2%;
        padding-left: 2%;
        margin-right: auto;
        margin-left: auto;
    }
    .provider-bg{
        background: linear-gradient(var(--primary-ss-color) 0 30%, white 70% 100%);
    }
    .home-services-img{
        width: 120px;
    }
    .home-mobile-pic{
        width: 30%;
    }
    .logo-img{
        height:50px;
    }
    #website-responsive .minusBg10, #website-responsive .minusBg12 {
        margin-top: 0;
    }

    .home-insurance-img{
        width: 60%;
    }

    #website-responsive .fs-ss-92{
        font-size: 55px;
    }
    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 45px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .fs-ss-68{
        font-size: 45px;
    }

    #responsive-changes .fs-ss-74{
        font-size: 45px !important;
    }

    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:40px;
    }

    #website-responsive .fs-ss-52{
        font-size:38px;
    }


    #website-responsive .fs-ss-48{
        font-size:36px;
    }

    #website-responsive .fs-ss-46{
        font-size:34px;
    }

    #website-responsive .fs-ss-40{
        font-size:32px;
    }

    #website-responsive .fs-ss-35{
        font-size:28px;
    }

    #website-responsive .fs-ss-32{
        font-size:26px;
    }

    #website-responsive .padding10xem, #website-responsive .padding7xem, #website-responsive .padding5xem{
        padding: 3em 0;
    }

    #website-responsive .padding3xem{
        padding: 2em 0;
    }

    /* .btn-ss-white{
        padding: 0;
    }
    .btn-ss-white:hover{
        padding: 0;
    }
    .btn-ss-white button{
        padding: 0;
    } */
    .page-banner-title{
        top:40%;
    }
    .about-weuse-section1, .about-weuse-section2, .about-weuse-section3, .about-weuse-section4{
        width: 100%;
        height: unset;
        min-height: unset;
        display: block;
        padding: 40px 30px !important;
        font-weight: 600;
    }
    .navbar{
        padding: unset;
    }
    
    /* .home-banner-content{
        position: absolute;
        top: 15%;
        width: 50%;
        left: 3%;
    } */
}

@media screen and (max-width: 767px) {
    #support-agent .support-popup {
        width: 80%; /* Use 90% of the screen width */
        height: 70%; /* Adjust height for smaller screens */
        bottom: 60px; /* Adjust spacing from the bottom */
        right: 5%; /* Center the popup horizontally */
    }

    #support-agent .support-icon {
        width: 40px; /* Smaller icon for mobile */
        height: 40px;
        font-size: 20px;
        bottom: 15px; /* Adjust position */
        right: 15px;
    }
    #popup .box {
        width: 80% !important;
        }
        
        #popup .close-icon {
        right: calc(25% - 80px) !important;
        top: calc(100vh - 85vh - 37px) !important;
        }

    .bg-qr{
        background: linear-gradient( #ffffff 20%, #55479e 0%, #55479e 80%, #55479e 0%) !important;
    }
    .w-3{
        width: 80% !important;
    }

    .w-6{
        width: 80% !important;
    }

    .fs-ss-16{
        font-size: 15px;
    }    

    .btn-bg-button{
        background-color: var(--primary-ss-color) !important;
        color: white !important;
    }

    .footer-logo{
        width: 130px !important;
        height: 130px !important;
        margin-bottom: 20px;
    }
    .spacer-ss-25{
        height: 15px;
    }
    .home-medical-img{
        width: 60%;
    }
    .home-billing-img{
        width: 80%;
    }
    .provider-pic-w{
        max-width: 150px;
    }
    .integrated-section-img-1, .integrated-section-img-2, .integrated-section-img-3, .integrated-section-img-4{
        background-size: cover;
        background-attachment: unset;
    }
    .review-section-img-1, .review-section-img-2, .review-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }
    .appointment-section-img-1, .appointment-section-img-2, .appointment-section-img-3{
        background-size: cover;
        background-attachment: unset;
    }

    #website-responsive .minusBg10 {
        margin-top: 0;
    }

    .home-practice-img{
        width: 40%;
    }
    .home-practice-animation{
        width: 70%;
    }

    #website-responsive .fs-ss-20{
        font-size:16px;
    }
    
    .slick-slide-content{
        top: 35%;
        width: 64%;
        left: 2vw;
    }
    .slick-slide-title-desc{
        font-size: 2.7vw;
        font-weight: 500;
        padding: 0 10px 3px 10px;
    }

    .slick-slide-title{
        font-size: 4vw;
        padding: 0 10px;
        top:20%;
        left: 2vw;
        line-height: 27px;
    }
    .slick-slide-btn{
        top: 60%;
    }
    
    .slick-slide-button{
        font-size: 2vw;
      }

    .home-banner-btn{
        font-size: 12px !important;
    }
    .page-banner-btn{
        margin-top: 10px;
    }
    .btn-ss-white-banner{
        font-size: 13px;
        /* margin-top: 20px; */
    }
    .btn-ss-primary-banner{
        font-size: 14px;
        margin-top: 20px;
    }
    
    .digital-marketing-icon{
        width: 60% !important;
    }
    .about-weuse-section{
        min-height: 100% !important;
    }
    .home-healthimages{
        width: 30%;
    }

    .home-healthimages img{
        width: 30%;
    }
    .page-banner-title{
        font-size: 4vw;
        top: 40%;
        line-height: 5.5vw;
    }
    .page-banner-text1{
        font-size: 3vw;
    }
    .page-banner-desc{
        font-size: 3vw;
        font-weight: 500;
        display: none;
        line-height: 3vw;
    }
    .about-connection-sec{
        min-height: 100%;
    }
    .text-home-onestop{
        font-size: 24px !important; 
    }
    .about-ourphilosophy{
        width: 100px;
        height: 80px;
    }
    #reviews-lb .lb{
        margin-top: 7px;
        margin-left: 0;
        padding-left: 0;
        border-left: unset;
    }

    .marginTopvitals1{
        margin-top: -110px;
    }
    
    .paddingTopvitals2{
        padding-top: 120px;
    }

    .emr_featured_col{
        /* middle */
        min-height: 0px;
    
    }
    .footer-links-section{
        padding-bottom: 0;
    }
    .ls-lg-ss-aim{
        letter-spacing: 2px;
    }

    .home-insurance-img{
        width: 50%;
    }
    .about-timing-bg{
        background: none;
    }

    #whatisccm-comprehensive .container-ss{
        max-width: 100% !important;
        padding-right: 0 !important;
        padding-left: 0 !important;
        margin-right: auto;
        margin-left: auto;

    }
    
}
  

@media screen and (max-width: 576px) {
    .w-icon{
        width: 44px !important;
    }

    .w-icon-2{
        width: 44px !important;
    }

    .hover-black{
        color: rgb(0, 0, 0) !important;
    }

    .fs-ss-16{
    font-size: 15px;
    }

    .btn-bg-button{
        background-color: var(--primary-ss-color) !important;
        color: white !important;
    }

    .lh-57{
        line-height: 2px;
    }

    .section1{
        min-height: 100%;
    }
    .section2{
        min-height: 100%;
    }
    .provider-img{
        width: 40%;
    }

    .slick-slide-title{
        font-size: 5vw;
        top:20%;
        line-height: 17px;
        left: 1vw;

    }

    .slick-slide-title-desc{
        top:45%;
        font-size: 4vw;
        font-weight: 500;
        
    }

    .slick-slide-title2{
        font-size: 3.7vw;
        top:20%;
        line-height: 17px;
        left: 1vw;
    }

    .slick-slide-btn{
        top: 60%;
        margin-left: 10px;
    }
    

    .slick-slide-button{
        font-size: 3.5vw;
      }

      .home-services-img{
        width:120px;
      }
    .home-mobile-pic{
        width: 50%;
    }

    #website-responsive .fs-ss-92{
        font-size: 45px;
    }

    #website-responsive .service-healthcare-bg  .fs-ss-92{
        font-size: 35px;
    }

    #home-we-aim .fs-ss-92{
        font-size: 35px;
    }

    #services-cta .fs-ss-92{
        font-size: 35px;
    }

    #website-responsive .fs-ss-68{
        font-size: 32px;
    }

    #responsive-changes .fs-ss-74{
        font-size: 40px !important;
    }

    
    #website-responsive .fs-ss-56, #website-responsive .fs-ss-58{
        font-size:24px;
    }
    #website-responsive .fs-ss-52{
        font-size:27px;
    }
    
    #website-responsive .fs-ss-48{
        font-size: 26px;
    }

    #website-responsive .fs-ss-46{
        font-size: 24px;
    }

    #website-responsive .fs-ss-40{
        font-size: 22px
    }

    #website-responsive .fs-ss-35{
        font-size: 22px;
    }

    #website-responsive .fs-ss-32{
        font-size: 20px;
    }

    #website-responsive .fs-ss-30, #website-responsive .fs-ss-28{
        font-size: 20px;
    }

    #website-responsive .fs-ss-24{
        font-size: 20px;
    }

    #website-responsive .fs-ss-26{
        font-size: 16px;
    }

    .navbar-brand .fs-ss-26{
        font-size: 19px;
    }

    #website-responsive .fs-ss-22{
        font-size: 20px;
    }
    #website-responsive .fs-ss-18, #website-responsive .fs-ss-17{
        font-size:16px;
    }
    .about-health-section .fs-ss-30{
        font-size: 23px !important;
    }

    .container-ss, .container-ss2{
        max-width: 96%;
        padding-right: 2% !important;
        padding-left: 2% !important;
        margin-right: auto;
        margin-left: auto;
    }
    
}



@media screen and (max-width: 480px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 440px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 400px) {
    .page-banner-title{
        top:40%;
    }
}

@media screen and (max-width: 320px) {
    .page-banner-title{
        top:35%;
    }
    
}

@media screen and (max-width: 280px) {
    
}




























/* This Project */
.header-logo{
    width: 270px;
}

.bg-home-common{
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 88vh;
}

.bg-home-banner1{
    background-image: url(../images/Home/New/Banner1.webp);
}

.bg-home-banner2{
    background-image: url(../images/Home/New/Banner2.webp);
}

.bg-home-banner3{
    background-image: url(../images/Home/New/Banner3.webp);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 88vh;
}

.bg-home-banner4{
    background-image: url(../images/Banners/VirtualScribe.webp);
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 88vh;
}

.bg-home-banner5{
    background-image: url(../images/Home/New/Banner4.webp);
}

.fs-banner{
    font-size: 3.8vw;
}

.fs-sub-banner{
    font-size: 1.9vw;
}

.text-menu{
    font-size: 16px;
    font-weight: 500;
    color: rgb(0, 0, 0);
}

.hover-pri{
    color: black;
}

.hover-pri:hover{
    color: var(--primary-ss-color);
}


.btn-pri{
    color: black;
    border: 1px solid var(--primary-ss-color);
    background-color: white;
}

.btn-pri-2{
    color: rgb(255, 255, 255);
    border: 1px solid var(--primary-ss-color);
    background-color: var(--primary-ss-color);
}

.btn-pri:hover{
    color: white !important;
    background-color: var(--primary-ss-color) !important;
}

.btn-pri-2:hover{
    color: white !important;
    background-color: #59189abb !important;
}


.bg-middle-banner2{
    background-image: url(../images/virtualscribe/MiddleBanner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bg-middle-banner-convo{
    background-image: url(../images/convoscribe/MiddleBanner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bg-middle-banner{
    background-image: url(../images/AboutUs/New/BgMiddleBanner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bg-common-banner{
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.bg-aboutus{
    background-image: url(../images/AboutUs/New/AboutUsBanner.webp);
}

.bg-careers{
    background-image: url(../images/careers/Banners.jpg);
}

.bg-faqs{
    background-image: url(../images/Banners/FAQsBanner.webp);
}
.bg-contactus{
    background-image: url(../images/Banners/ContactBanner.webp);
}

.bg-privacy{
    background-image: url(../images/Banners/PrivacyBanner.webp);
}

.bg-terms{
    background-image: url(../images/Banners/TermsBanner.webp);
}

.bg-blogs{
    background-image: url(../images/Banners/BlogsBanner.webp);
}

.bg-plans{
    background-image: url(../images/SignUp/AdobeStock_605977628_Preview.png);
}




.padding-middle-banner{
    padding-top: 15vh;
    padding-bottom: 15vh;

}

.bg-grey{
    background-color: #f7f6f6 !important;
}

.bg-grey2{
    background-color: #cfcfcf !important;
}

.bg-grey2:hover{
    color: white !important;
    background-color: var(--primary-ss-color) !important;
}

.footer-logo{
    width: 120px;
}

.hover-black{
    color: white !important;
}

.hover-black:hover{
    color: black !important;
}

.lh-banner{
    line-height: 56px !important;
}

.lh-heading{
    line-height: 50px !important;
}

.lh-para{
    line-height: 27px ;
}

.lh-para2{
    line-height: 25px !important ;
    font-weight: 400 !important;
    letter-spacing: 1.3px;

}

.w-icons{
    width: 60px;
}

.w-icons-about-us{
    width: 120px;
}

.navbar-toggler:focus{
    border: none !important;
}

.btn-bg-button{
    background-color: white ;
}

.btn-bg-button:hover{
    background-color: rgb(236, 236, 236) ;
}

.bg-footer{
    background-image: url(../images/Home/New/FooterBanner.webp);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.section-img-1{
    background-position: left;
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.section-img-2{
    background-position: right;
    background-size: contain;
    background-position: 200%;
    background-repeat: no-repeat;
    background-attachment: fixed;
}



.faqs-heading{
    font-size: 18px;
    font-weight: 400;
}
.faqs-ans{
    font-size: 16px;
    font-weight: 400 !important;
}

.banner-title{
    color: var(--white-color);
    font-family: var(--secondary-ss-font-family);
    position: absolute;
    /* text-transform: uppercase; */
    top: 38%;
    left: 8vw;
    font-size: 60px;
    font-weight: 700;
    line-height: 4.3vw;
    letter-spacing: 1px;
}

.heading-link{
    text-decoration: none;
    color: black;
}
.heading-link:hover{
    color: var(--primary-ss-color);
}

.heading-ss{
    font-size: 32px;
    font-weight: 700;
}

.form-btn-ss{
    color: white;
    background-color: var(--primary-ss-color);
    padding: 8px 20px;
    border-radius: 5px;
    font-size: 18px;
    /* font-family: ; */
    font-weight: 500;
    border: none;
}

.contact-border{
    border-right: 2px solid #dee2e6;
}


.navbar-toggler:focus{
    box-shadow: none;
}

.simple-text{
    color: white !important;
}

.letter{
    letter-spacing: 0px;
}








@media screen and (max-width: 991px) {
    .w-6{
        width: 40% !important;
    }

    .bg-home-common{
    background-position: top right;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    height: 40vh !important;
    }
    .fs-banner{
        font-size: 3.4vw !important;
    }
    
    .fs-sub-banner{
        font-size: 2.2vw !important;
    }
    .footer-logo{
        width: 130px !important;
        height: 130px !important;
        margin-bottom: 20px;
    }
    .banner-title{
        top:25%;
    }
}

@media screen and (max-width: 768px) {
    /* Landing Page */
    .w-slider-client{
        width: 150px !important;
    }

    .bg-landing-page{
        background-image: none !important;
        background-repeat: no-repeat;
        background-size: 110%;
        background-position: 50%;
    }
    
    .fs-ss-18-landing-page{
        font-size: 15px !important ;
    }

    .fw-400-500-mb{
        font-weight: 500 !important;
    }

    .w-landing-page-footer{
        width: 80px !important;
    }

    .fs-btn-landing-page{
        font-size:14px !important;
    }
    
    .fs-sub-banner2{
        font-size:16px !important;
    }

    #landing-page .faqs-heading{
        font-size: 15px;
    }

    #landing-page .faqs-ans{
        font-size: 14px;
    }
    
    #landing-page .parent{
        position: static !important;
    }
    
    #landing-page .child{
        position: static !important;
        top: none !important;
        left: none !important;
    }

    #landing-page .fs-ss-35{
        font-size:22px !important;
    }

    #landing-page .fs-ss-32{
        font-size:22px;
    }

    #landing-page .fs-ss-17{
        font-size:14px;
    }

    #landing-page .fs-ss-68{
        font-size:35px;
    }

    #landing-page .fs-ss-20{
        font-size:16px;
    }

    #landing-page .fs-ss-22{
        font-size:16px;
    }

    #landing-page .fs-ss-24{
        font-size:18px;
    }

    #landing-page .fs-ss-56{
        font-size:35px;
    }
    
    #landing-page .fs-ss-48{
        font-size:20px;
    }

    #landing-page .fs-ss-12{
        font-size:12px;
    }

    #landing-page .fs-ss-13-card{
        font-size:9px !important;
    }

    #landing-page .fs-ss-13{
        font-size:12px !important;
    }

    #landing-page .fs-ss-btn{
        font-size:14px !important;
    }

    #landing-page .fs-banner{
        font-size:25px !important;
    }

    #landing-page .fs-sub-banner{
        font-size:20px !important;
    }

    #landing-page .slick-dots li button:before{
        color: white !important;
    }

    .border-bottom-mb{
        border-bottom: 1px solid rgba(128, 128, 128, 0.178);
    }

    /* Landing Page */

    .fs-ss-copyright{
        font-size: 12px !important;
    }

    .banner-btn-padding{
        padding: 8px 21px !important;
    }

    .fs-ss-13{
        font-size: 8px !important; 
    }

    #form .w-50{
        width: 100% !important;
    }

    #slick-testimonials .slick-prev{
        z-index: -100 !important ;
        left: 0% !important;
        display: none !important;
    }
    
    #slick-testimonials .slick-next{
        display: none !important;
        right: 0% !important;
    }
    .fs-ss-testimonials{
        font-size: 14px !important;
    }

    .vh-50{
        height: max-content !important;
    }
    
    .bg-home-banner3{
        background-image: url(../images/Home/New/Banner3.webp);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: cover;
        position: none !important;
        height: auto !important;
    }
    .bg-home-banner4{
        background-image: url(../images/Banners/VirtualScribe.webp);
        background-position: top right;
        background-repeat: no-repeat;
        background-size: cover;
        position: none !important;
        height: auto !important;
    }
    .w-blog-image{
        width: 100% !important;
    }
    .w-6{
        width: 80% !important;
    }
    .carousel-control-next{
        width: 8% !important;
    }
    .carousel-control-prev{
        width: 8% !important;
    }
    .lh-para{
        line-height: 20px ;
    }
    .ls-1{
        letter-spacing: 1px !important;
        line-height: 25px !important;
    }
    
    .fs-banner{
        font-size: 20px !important;
    }
    
    .fs-sub-banner{
        font-size: 12px !important;
    }

    .bg-home-common{
        height: 30vh !important;
        background-position: top center !important;
    }
    
    .bg-ss-primary3{
        background: rgb(255,255,255);
        background: linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(247,247,255,1) 0%) !important;
    }
    .section-img-2{
        background-position: 300%;
    }

    .hover-black{
        color: rgb(0, 0, 0) !important;
    }

    .banner-title{
        /* font-size: 5.5vw; */
        font-size: 28px;
        top: 47%;
        line-height: 5.5vw;
    }

}

@media screen and (max-width: 480px) {
    .banner-title{
        top:45%;
    }
    .heading-ss{
        font-size:24px;
    }

    .contact-border{
        border-right: 0px solid #dee2e6
    }
    
}

@media screen and (max-width: 400px) {
    .banner-title{
        top:42%;
    }

}

@media screen and (max-width: 320px) {
    .banner-title{
        top:35%;
    }
    
}

#nav-tab div{
    color: white !important;
    text-align: center;
    font-size: 16px !important;
}

#nav-tab .icon-circle{
    font-size: 18px !important;
}

#nav-tab div:hover{
    background-color: white !important;
    color: var(--primary-ss-color) !important;
}


.fs-card{
    font-size: 54px;
}

#cards .card-type{
    margin-right: 40px;
}

.active{
    color: black !important;
}

#cards .card-type:hover{
    border: 2px solid #7365bb;
}

.text-justify{
    text-align: justify !important;
}

.hr-plans{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    opacity: 1;
}

.w-signup-logo{
    width: 60px;
}

.bg-signup{

    background-color: var(--primary-ss-color);
}

.sign-up-hover{
    color: white;
}
.sign-up-hover:hover{
    color: var(--primary-ss-color) !important;
}

.active2 .active{
    background-color: white;
    color: var(--primary-ss-color);
}



/* Changes From Here */
.home-ban-text{
    position: absolute !important;
    left: 5%;
    top: 12% !important;
}

.navbar-toggler{
    background-color: var(--primary-ss-color) !important;
}
.navbar-toggler-icon{
    background-image: url(../images/menu.png) !important;
}

.footer-btn{
    transition: transform .2s;
    background-color: var(--primary-ss-color);
    color: white;
}

.footer-btn:hover{ 
    color: white;
    background-color: var(--primary-ss-color);
        -ms-transform: scale(1.1); 
        -webkit-transform: scale(1.1);
        transform: scale(1.1); 
}

.btn-pricing {
    background-color: var(--primary-ss-color);
    color: white;
    font-weight: 600;
}

.btn-pricing:hover {
    background-color: #55479e;
    color: white;
}

.bg-header{
    background-color: #f2f2f2;
}

.btn-middle-banner{
    background-color: white;
    color: black;
}

.btn-middle-banner:hover{
    background-color: rgb(221, 221, 221);
    color: black;
}

.fs-ss-btn{
    font-size: 18px !important;
}

.fs-ss-btn-demo{
    font-size: 16px ;
}

.mb-menu{
    border: 1px solid var(--primary-ss-color) !important;
    background-color: rgb(rgba(238, 144, 144, 0)) !important;
}

.header-logo-mb{
    width: 210px;
}

.btn-footer-color{
        color: white !important;
        background-color: var(--primary-ss-color);
        border: none !important;
}

.btn-footer-color:hover{
    color: white !important;
    background-color: rgb(65, 65, 65) !important;
}

@media screen and (max-width: 576px) {
    .bg-common-banner{
        background-position: center;
    }
    .section-img-1{
        background-size: cover;
        background-attachment: unset;
    }
    
    .section-img-2{
        background-size: cover;
        background-attachment: unset;
    }
    .fs-ss-btn{
        font-size: 10px !important;
    } 
    .fs-ss-btn-demo{
        font-size: 15px !important;
    }
    .header-logo{
        width: 200px !important ;
    }

}

.w-icons-about-us{
    width: 80px ;
}

@media screen and (min-width: 577px) and (max-width: 991px) {
    .bg-ss-primary3{
        background: rgb(255,255,255);
        background: linear-gradient(360deg, rgba(255,255,255,1) 0%, rgba(247,247,255,1) 0%) !important;
    }
    
    .section-img-1{
        background-size: cover;
        background-attachment: unset;
    }
    
    .section-img-2{
        background-size: cover;
        background-attachment: unset;
    }
}

.stepper .active {
    background-color: var(--primary-ss-color) !important;
    color: #fff !important;
}

.stepper .completed {
    background-color: var(--primary-ss-color) !important;
    color: #fff !important;
}

.w-3{
    width: 19%;
}

.w-6{
    width: 28%;
}

.w-icon{
    width: 33%;
}


.w-icon-2{
    width: 80px;
}

.bg-ss-light{
    background-color: rgb(240, 240, 240)
}

.bg-testimonials{
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    background-image: url(../images/Home/BgTestimonials.webp);
}

.bg-qr{
    background: linear-gradient( #ffffff 20%, #55479e 0%, #55479e 80%, #ffffff 0%);
}

#home .accordion-button::after{
    background-image: none !important;
}

#home .accordion-button:not(.collapsed)::after{
    background-image: none !important;
}

#home .accordion-button:not(.collapsed) {
    background-color: #ffffff !important;
    color: var(--primary-ss-color) !important;
    border-bottom: 1px solid #dee2e6;
}

.bg-qr-position{
    position: relative;
}

.bg-qr-position-img{
    position: absolute;
    bottom: -248px;
    left: 90px;
}

.slick-next{
    right: 40px !important;
}

.slick-prev{
    left: 40px !important;
}


.btn-primary-gradient{
    color: white !important;
    background-color: var(--primary-ss-color) !important;
    border: none !important;
}

.btn-primary-gradient:hover{
    color: white !important;
    background-color: rgb(112, 40, 184) !important
}

.bg-ss-primary3{
    background: rgb(255,255,255);
    background: linear-gradient(180deg, rgba(255,255,255,1) 20%, rgba(247,247,255,1) 20%);
}

.text-justify-imp{
    text-align: justify !important;
}

.w-blog-image{
    width: 250px;
}

.hover-blogs:hover .text-black{
    color: var(--primary-ss-color) !important;
}

.py-2-5{
    padding-top: 0.8rem ;
    padding-bottom: 0.8rem ;
}

.hover1{
    display: none ;
}

.right-menu:hover .hover1{
    display: block;
}

.border-bottom-grey{
    border-bottom: 1px solid rgb(170, 170, 170) !important ;
}

.bg-dark-variant{
    background-color: #0e0c16 !important;
}

.w-icons-hiw{
    width: 100px;
}

.bg-light{
    background-color: #f7f6f6 !important;
}

.bg-light2{
    background-color: #e4e4e4 !important;
}

.border-light{
    border-color: #f7f6f6 !important;
}

.text-green{
    color: rgb(63, 82, 19)   
}

.w-pricing-1{
    width: 40%;
}

.w-pricing-2{
    width: 20%;
}

.ms-auto{
    margin-left: auto !important;
}

.me-auto{
    margin-right: auto !important;
}

.react-tabs__tab--selected{
    background-color: var(--primary-ss-color) !important;
    color: white !important;
    border-radius: 100px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
    border: none !important;
}

.ls-1{
    letter-spacing: 1.5px;
    line-height: 35px;
}

.carousel-control-prev{
    width: 10%;
}

.carousel-control-next{
    width: 10%;
}

.carousel-control-prev-icon{
    background-color: var(--primary-ss-color) !important;
    border-radius: 100px;
    padding: 0px;
    width: 20px;
}    

.carousel-control-next-icon{
    background-color: var(--primary-ss-color) !important;
    border-radius: 100px;
    padding: 0px;
    width: 20px;
}    

.hover-ss-primary{
    color: #212529;
}

.hover-ss-primary:hover{
    color: var(--primary-ss-color);
}

.hover-ss-primary2{
    color: var(--primary-ss-color);
    text-decoration-line: underline;
    text-decoration-thickness: 4px !important;
}

.hover-ss-primary2:hover{
    color: #212529;
}

.hover-bg-ss-primary{
    background-color: var(--primary-ss-color);
}

.hover-bg-ss-primary:hover{
    background-color: #212529;
}

.h-100{
    width: 100% !important;
    height: 100% !important;
}

.bottom-border-1{
    border-bottom: 1px solid rgb(172, 172, 172);
}

#slick-testimonials .slick-prev{
    z-index: 100 !important ;
    left: 0% !important;
}

#slick-testimonials .slick-next{
    right: 0% !important;
}

.vh-50{
    height: 60vh;
}

.fs-ss-testimonials{
    font-size: 1.05vw;
}

.z-index-10{
    z-index: 1000 !important;
}

.bg-light-form{
    background-color: #f3f3f3 ;
}

.lh-form{
    line-height: 1.5rem;
}

.calendly-inline-widget{
    height: 700px !important;
}

.banner-btn-padding{
    padding: 13px 52px;
}

.bg-ss-light2{
    background-color: #f4f4f4;
}

.fs-ss-copyright{
    font-size: 16px;
}

.w-btn-header{
    width: 88%;
    font-size: 14px !important;
}

.line-through{
    text-decoration: line-through ;
    color: #9d94cf;
}

.bg-hover-primary-white{
    background-color: var(--primary-ss-color);
    color: white;
}

.bg-hover-primary-white:hover{
    background-color: white;
    color: var(--primary-ss-color);
}

.bg-hover-white-primary{
    background-color: white;
    color: var(--primary-ss-color);}

.bg-hover-white-primary:hover{
    background-color: var(--primary-ss-color);
    color: white;}

.bg-hover-primary-border{
    border: 1px solid var(--primary-ss-color);
    background-color: white;
    color: var(--primary-ss-color);
}

.bg-hover-primary-border:hover{
    background-color: var(--primary-ss-color);
    color: white;
}

.border-bg-hover-primary-border{
    border: 1px solid var(--primary-ss-color);
    background-color: var(--primary-ss-color);
    color: var(--primary-ss-color);
    color: white;
}

.border-bg-hover-primary-border:hover{
    border: 1px solid var(--primary-ss-color);
    background-color: transparent;
    color: var(--primary-ss-color);
}

.border-bg-white-hover-primary-border{
    border: 1px solid var(--primary-ss-color);
    background-color: var(--primary-ss-color);
    color: var(--primary-ss-color);
    color: white;
}

.border-bg-white-hover-primary-border:hover{
    border: 1px solid white;
    background-color: white;
    color: var(--primary-ss-color);
}

.text-black{
    color: black !important;
}


/* Landing Page */
.text-ss-primary-grad{
    background: -webkit-linear-gradient(90deg, rgba(90,24,154,1) 40%, rgb(185, 115, 255) 100%, rgba(255,255,255,1) 100%);;
  -webkit-background-clip: text;
  background-clip: text ;
  -webkit-text-fill-color: transparent;
}

#landing-page .fs-ss-16{
    font-size: 16px;
    line-height: 30px !important;
}

.ls-2{
    letter-spacing: 0.3vw;
}

.bg-landing-page{
    background-image: url(../images/LandingPage/BgBanner\ \(2\).png);
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: 50%;
}

.bg-grey-landing-page{
    background-image: url(../images/LandingPage/MiddleBg.webp);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.bg-grey2-landing-page{
    background-color: #ebebeb;
}

.bg-transparent{
    background-color: transparent !important;
}

.accordion-button{
    background-color: transparent !important;
}

#landing-page .slick-prev::before{
    color: white !important;
}

#landing-page .slick-prev::before, #landing-page .slick-next::before{
    color: white !important;
}

#slider-client-icons .slick-prev::before, #slider-client-icons .slick-next::before{
    color: var(--primary-ss-color) !important;
}

#slider-client-icons .slick-dots li button:before{
    color: var(--primary-ss-color) !important;
}

.bg-middle-banner-landing-page{
    background-image: url(../images/LandingPage/BgMiddleBanner.webp);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.w-landing-page-footer{
    width: 70px;
}

.fs-btn-landing-page{
    font-size: 1.2vw ;
}

.fs-sub-banner2{
    font-size: 1.45vw;
}

#landing-page .parent{
    position: relative;
}

#landing-page .child{
    position: absolute;
    top: 25%;
    left: 10%;
}

.fw-400-500-mb{
    font-weight: 400;
}

.fs-ss-18-landing-page{
    font-size: 18px ;
}

.w-slider-client{
    width: 200px;
}

.me-6{
    margin-right: 20px;
}

#popup .box {
    position: relative;
    width: 100%;
    min-height: max-content;
    margin-top: calc(100vh - 70vh - 20px);
  }
   
  #popup .close-icon {
    right: calc(10% - 30px);
    top: calc(100vh - 70vh - 33px);
   
  }
#popup .popup-box {
position: fixed;
background: #00000050;
width: 100%;
height: 100vh;
z-index: 10000 !important;
top: 0;
left: 0;
}

#popup .box {
position: relative;
width: 50%;
margin: 0 auto;
height: auto;
max-height: 70vh;
margin-top: calc(100vh - 85vh - 20px);
background: #fff;
border-radius: 4px;
padding: 3px;
border: 1px solid #999;
overflow: auto;
}

#popup .close-icon {
content: 'x';
cursor: pointer;
position: fixed;
right: calc(25% - 30px);
top: calc(100vh - 85vh - 33px);
background: #ededed;
width: 25px;
height: 25px;
border-radius: 50%;
line-height: 20px;
text-align: center;
border: 1px solid #999;
color: var(--primary-ss-color);
font-size: 20px;
}

.skip-top-content {
    position: absolute;
    color: transparent !important;
}

.hover-ss-social-media{
    color: white;
}

.hover-ss-social-media:hover{
    color: var(--primary-ss-color);
}

.offer-parent{
    position: relative;
}

.offer{
    position: absolute;
    top: 5px;
    left: 15px; 
}

.react-tel-input .form-control {
    background: none !important;
    border: 0px !important;
}


.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
    background-color: transparent !important;
}

.react-tel-input .selected-flag {
    background-color: transparent !important;
}

.react-tel-input .flag-dropdown.open {
    background-color: transparent !important;
}

.border-primary {
    border-color: var(--primary-ss-color) !important;
}